<template>
  <div>
    <b-card
      title="จัดการเครดิต"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="tableQuery.phoneno"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group />
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMember"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-card-body v-if="memberInfo" />
        <b-row v-if="memberInfo">
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              เบอร์โทร / ยูส
            </h5>
            <b-card-text>
              {{ memberInfo.phoneno }} / {{ memberInfo.username }}
            </b-card-text>
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              ชื่อ-สกุล
            </h5>
            <b-card-text>
              {{ memberInfo.fname }} {{ memberInfo.lname }}
            </b-card-text>
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              บัญชี
            </h5>
            <b-card-text>
              <img
                :src="getBankImage(memberInfo.bank_code)"
                style="width: 24px; margin-bottom:5px"
              > {{ memberInfo.bank_name }} - {{ memberInfo.bank_acc_no }}
            </b-card-text>
          </b-col>
          <b-col
            md="4"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              เครดิตปัจจุบัน
            </h5>
            <b-card-text class="text-right">
              {{ memberInfo.wallet.credit }}
            </b-card-text>
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row v-if="memberInfo">
      <b-col md="12">
        <b-card>
          <h2>เติมเครดิต</h2>
          <b-form
            class="mt-10"
            @submit.prevent
          >
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="วันที่ (ตามสลิปเท่านั้น)"
                  label-for="mc-first-name"
                >
                  <flat-pickr
                    v-model="topupInfo.topup_date"
                    class="form-control"
                    :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="เวลา (ตามสลิปเท่านั้น)"
                  label-for="mc-first-name"
                >
                  <!-- <b-form-timepicker
                    v-model="topupInfo.topup_time"
                    v-bind="labels['th'] || {}"
                    locale="th"
                  /> -->
                  <b-input-group class="mb-3">
                    <b-form-input
                      id="example-input"
                      v-model="topupInfo.topup_time"
                      type="text"
                      placeholder="HH:mm"
                      max-length="5"
                      maxLength="5"
                    />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="topupInfo.topup_time"
                        button-only
                        right
                        v-bind="labels['th'] || {}"
                        locale="th"
                        aria-controls="example-input"
                        hide-header
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="จำนวนเงิน"
                  label-for="mc-first-name"
                >
                  <cleave
                    id="topup-amopunt"
                    v-model="topupInfo.amount"
                    class="form-control"
                    :raw="false"
                    :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand', rawValueTrimPrefix:true }"
                    placeholder="0,000.00"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="ช่องทางการฝาก"
                  label-for="mc-first-name"
                >
                  <v-select
                    v-model="topupInfo.from_account"
                    label="title"
                    :options="bankaccounts"
                  >
                    <template #option="data">
                      <!-- <feather-icon
                        :icon="icon"
                        size="16"
                        class="align-middle mr-50"
                      /> -->
                      <span>{{ data.bank }} {{ data.acc_no }} {{ data.acc_name }}</span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="เหตุผลในการเติมมือ"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    v-model="topupInfo.reason"
                    placeholder="เหตุผล"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="topupInfo.turnlimit"
                  name="flavour-1"
                  class="mb-1"
                >
                  <b-form-checkbox
                    :value="true"
                    switch
                  >
                    {{ (topupInfo.turnlimit || []).length > 0 ? 'ตั้งเทิร์นถอนเงิน':'ตั้งเทิร์น' }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
              <b-col
                v-if="(topupInfo.turnlimit || []).length > 0"
                md="6"
              >
                <b-form-group
                  label="เทิร์นที่ต้องทำเพิ่ม"
                  label-for="mc-first-name"
                >
                  <cleave
                    id="topup-amopunt"
                    v-model="topupInfo.more_turn_amount"
                    class="form-control"
                    :raw="false"
                    :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand', rawValueTrimPrefix:true }"
                    placeholder="0,000.00"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="(topupInfo.turnlimit || []).length > 0"
                md="6"
              >
                <b-form-group
                  label="เทิร์นที่ต้องทำ (คำนวนทบเทิร์นเดิม)"
                  label-for="mc-first-name"
                >
                  <cleave
                    id="topup-amopunt"
                    v-model="topupInfo.withthdrawable_turn_amount"
                    class="form-control"
                    :raw="false"
                    :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand' }"
                    readonly
                    placeholder="0,000.00"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="แนบสลิปลูกค้า"
                  label-for="mc-first-name"
                >
                  <b-form-file
                    ref="topup-slip"
                    placeholder="แนบสลิปลูกค้า"
                    drop-placeholder="ลากสลิปมาใส่ที่นี่"
                    accept="image/jpeg, image/png, image/gif"
                    @change="uploadSlipImage($event)"
                  />
                  <img
                    id="slipImage"
                    :src="topupInfo.slipImage"
                    style="width:100%;height:100%"
                  >
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                style="color: #ff0000;"
              >
                *** การเติมเงินโดยไม่มีสลิป หรือ สลิปไม่ถูกต้อง ผู้เติมต้องรับผิดชอบเงินเต็มจำนวนที่เติม ***
              </b-col>
              <!-- submit and reset -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="topup"
                >
                  เติมเครดิต
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearTopup"
                >
                  ยกเลิก
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card>
          <h2>ตัดเครดิต</h2>
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="จำนวนเงิน"
                  label-for="mc-first-name"
                >
                  <cleave
                    id="topup-amopunt"
                    v-model="deductInfo.amount"
                    class="form-control"
                    :raw="false"
                    :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand' }"
                    placeholder="0,000.00"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="เหตุผลในการตัดเครดิต"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    v-model="deductInfo.reason"
                    placeholder="เหตุผล"
                  />
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="deductCredit"
                >
                  ตัดเครดิต
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearSearch"
                >
                  ยกเลิก
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card>
          <h2>แจ้งถอนเงิน</h2>
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="จำนวนเงิน"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    v-model="withdrawInfo.amount"
                    placeholder="จำนวนเงิน"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="เหตุผลในการแจ้งถอนเงิน"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    v-model="withdrawInfo.reason"
                    placeholder="เหตุผล"
                  />
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="withdrawRequest"
                >
                  แจ้งถอนเงิน
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearSearch"
                >
                  ยกเลิก
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BFormFile, BTable, BBadge, BFormGroup, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BFormTimepicker, BCardText, BImg, BFormCheckbox, BFormCheckboxGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormFile,
    BCardText,
    BFormCheckbox,
    BFormCheckboxGroup,
    // BImg,
    Cleave,
    // BFormSelect,
    // BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormTimepicker,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      slipImage: false,
      pageOptions: [100, 200, 500, 1000],
      bankaccounts: [],
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      topupInfo: {
        amount: '0.0',
        topup_date: moment().format('YYYY-MM-DD'),
        topup_time: moment().format('HH:mm'),
        action: 'manual',
        more_turn_amount: '0.0',
        withthdrawable_turn_amount: '0.0',
      },
      withdrawInfo: {
        amount: 0.0,
        reason: '',
      },
      deductInfo: {
        amount: 0.0,
        reason: '',
      },
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        { key: 'balance', label: 'คงเหลือ' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      memberInfo: null,
      labels: {
        th: {
          labelHours: 'ชั่วโมง',
          labelMinutes: 'นาที',
          labelSeconds: 'วินาที',
          labelIncrement: 'เพิ่ม',
          labelDecrement: 'ลด',
          labelSelected: 'เลือก',
          labelNoTimeSelected: 'เลือกเวลา',
        },
      },
      bankMaps: {
        '004': 'กสิกรไทย',
        '014': 'ไทยพาณิชย์',
        '006': 'กรุงไทย',
        TMW: 'ทรูวอลเล็ท',
      },
      /* eslint-disable global-require */
    }
  },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter(f => f.sortable)
    //     .map(f => ({ text: f.label, value: f.key }))
    // },
    more_turn_amount() {
      return this.topupInfo.more_turn_amount || 0
    },
  },
  watch: {
    more_turn_amount(newVal) {
      this.topupInfo.withthdrawable_turn_amount = parseFloat((this.topupInfo.more_turn_amount || '').replace(/,/ig, '')) + parseFloat(this.memberInfo.total_turn_amount)
    },
    // topupInfo: {
    //   handler(newVal, _) {
    //     console.log('watch:topupInfo', newVal, _)
    //     if ((newVal.turnlimit || []).length < 1) {
    //       delete this.topupInfo.more_turn_amount
    //     } else {
    //       this.topupInfo.withthdrawable_turn_amount = parseFloat((this.topupInfo.more_turn_amount || '').replace(/,/ig, '')) + parseFloat(this.memberInfo.total_turn_amount)
    //     }
    //   },
    //   deep: true,
    // },
  //   'topupInfo.more_turn_amount': newVal => {
  //     this.topupInfo.withthdrawable_turn_amount = parseFloat(newVal) + parseFloat(this.topupInfo.amount)
  //   },
  },
  mounted() {
    this.totalRows = this.items.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined)
      this.tableQuery.phoneno = this.$route.params.user
    this.fetchRecords()
    this.fetchBankAccounts()
  },
  methods: {
    calculateWithdrawTurnAmount() {
      console.log('calculateWithdrawTurnAmount')
      this.topupInfo.withthdrawable_turn_amount = parseFloat(this.topupInfo.more_turn_amount) + parseFloat(this.topupInfo.amount)
    },
    searchMember() {
      this.fetchRecords()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.memberInfo = null
    },
    clearTopup() {
      this.topupInfo = {
        amount: '0.0',
        topup_date: moment().format('YYYY-MM-DD'),
        topup_time: moment().format('HH:mm'),
        action: 'manual',
      }
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts')
      this.bankaccounts = _bankaccounts.bankaccounts.data.map(i => ({
        ...i,
        title: `${this.bankMaps[i.bank_code]} ${i.acc_no} ${i.acc_name}`,
        bank: {
          '004': 'kbank', '014': 'scb', '006': 'krungthai', TMW: 'tmw',
        }[i.bank_code],
      })).filter(bank => ['IN', 'IN-OUT'].indexOf(bank.acc_type) >= 0)
      this.bankaccounts.push({
        title: 'ทรูมันนี่', acc_no: 'tmw', bank: 'tmw', acc_name: 'ทรูมันนี่วอลเล็ท',
      })
      this.bankaccounts.push({
        title: 'อื่นๆ', acc_no: 'other', bank: 'other', acc_name: 'อื่นๆ',
      })
      this.bankaccounts.push({
        title: 'โปรโมชั่นอื่นๆ', acc_no: 'promotion', bank: 'promotion', acc_name: 'โปรโมชั่น (ไม่ออโต้)',
      })
      // this.bankaccounts
    },
    async fetchRecords() {
      if (this.tableQuery.phoneno === null || this.tableQuery.phoneno === undefined) {
        this.items = []
        return this.items
      }

      let fieldName = 'phoneno'
      if (/[a-zA-Z]/.test(this.tableQuery.phoneno))
        fieldName = 'username'

      const { data: memberApiResult } = await this.$http.get(`/users/user-info?with_deposits=N&searchBy=${fieldName}&${fieldName}=${this.tableQuery.phoneno}&user=${this.tableQuery.phoneno}`)
      this.memberInfo = memberApiResult.user
      // this.items = _wallettx.message.data
      // this.totalRows = _wallettx.message.total
      return this.items
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.tableQuery.page = 1
    },
    async uploadSlipImage(event) {
      this.slipImage = false
      const fileReader = new FileReader()
      const ctx = this
      fileReader.onload = () => {
        console.log(fileReader.result)
        ctx.topupInfo.slipImage = fileReader.result
        document.getElementById('slipImage').src = fileReader.result
      }
      fileReader.readAsDataURL(event.target.files[0])
      await this.delay(3)
      this.$forceUpdate()
      // console.log('delay 3 secs')
      // this.slipImage = true
    },
    async delay(x) {
      return new Promise(resolve => {
        setTimeout(() => resolve(true), x * 1000)
      })
    },
    async topup() {
      //
      const errorTitle = 'เติมเครดิต'
      if (moment(this.topupInfo.topup_date, 'YYYY-MM-DD').isAfter(moment())) {
        this.$swal({
          title: errorTitle,
          text: 'วันที่สลิปไม่ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if ((this.topupInfo.topup_time || '').length > 5)
        this.topupInfo.topup_time = this.topupInfo.topup_time.substring(0, 5)

      if ((this.topupInfo.topup_time || '').length !== 5 || (this.topupInfo.topup_time || '').indexOf(':') < 0) {
        this.$swal({
          title: errorTitle,
          text: 'เวลาสลิปไม่ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (moment(`${this.topupInfo.topup_date} ${this.topupInfo.topup_time}`, 'YYYY-MM-DD HH:ss').isAfter(moment())) {
        this.$swal({
          title: errorTitle,
          text: 'วันที่และเวลาสลิปไม่ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (parseFloat((`${this.topupInfo.amount}`).replace(/,/ig, '')) > 2000000 || parseFloat((`${this.topupInfo.amount}`).replace(/,/ig, '')) <= 0) {
        this.$swal({
          title: errorTitle,
          text: 'จำนวนเงินฝากไม่ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (this.topupInfo.from_account === undefined || this.topupInfo.from_account == null) {
        this.$swal({
          title: errorTitle,
          text: 'กรุณาเลือกบัญชีรับเงินของเว็บให้ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (this.topupInfo.reason === undefined || this.topupInfo.reason == null || `${this.topupInfo.reason}`.length < 3) {
        this.$swal({
          title: errorTitle,
          text: 'กรุณาระบุเหตุผลในการเติมเงิน',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (this.topupInfo.slipImage === undefined || this.topupInfo.slipImage == null) {
        this.$swal({
          title: errorTitle,
          text: 'กรุณาอัพสลิปจากลูกค้า',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      const ctx = this
      this.$swal({
        title: errorTitle,
        text: `เติมเครดิตให้ ${this.memberInfo.phoneno} - ${this.memberInfo.fname} ${this.memberInfo.lname} จำนวน ${this.topupInfo.amount}, วันที่ ${moment(this.topupInfo.topup_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${this.topupInfo.topup_time}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.post('users/manual-topup', { topup: ctx.topupInfo, user: ctx.memberInfo }, { validateStatus: status => !!status })
        return { data: { success: false } }
      }).then(result => {
        if (result.data.success) {
          ctx.$bvToast.toast(`เติมเครดิตให้ ${ctx.memberInfo.phoneno} - ${ctx.memberInfo.fname} ${ctx.memberInfo.lname} จำนวน ${ctx.topupInfo.amount}, วันที่ ${moment(ctx.topupInfo.topup_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${ctx.topupInfo.topup_time} เรียบร้อย`, {
            variant: 'success',
            title: 'เติมเงินสำเร็จ',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
          })
          ctx.clearTopup()
          return
        }
        ctx.$bvToast.toast(`เติมเครดิตให้ ${ctx.memberInfo.phoneno} - ${ctx.memberInfo.fname} ${ctx.memberInfo.lname} จำนวน ${ctx.topupInfo.amount}, วันที่ ${moment(ctx.topupInfo.topup_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${ctx.topupInfo.topup_time} ไม่สำเร็จ เนื่องจาก ${result.data.error_message}`, {
          variant: 'danger',
          title: 'เติมเงินไม่สำเร็จ',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      })

      console.log('')
    },
    async deductCredit() {
      //
      if (this.deductInfo.reason === undefined || this.deductInfo.reason == null || `${this.deductInfo.reason}`.length < 5) {
        this.$swal({
          title: 'การคัดเครดิต',
          text: 'กรุณาระบุเหตุผลในการคัดเครดิต',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (parseFloat((`${this.deductInfo.amount}`).replace(/,/ig, '')) > 2000000 || parseFloat((`${this.deductInfo.amount}`).replace(/,/ig, '')) <= 0) {
        this.$swal({
          title: '',
          text: 'จำนวนเงินไม่ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      const ctx = this
      this.$swal({
        title: 'การคัดเครดิต',
        text: `ตัดเครดิตของ ${this.memberInfo.phoneno} - ${this.memberInfo.fname} ${this.memberInfo.lname} จำนวน ${this.deductInfo.amount}, วันที่ ${moment().format('DD/MM/YYYY HH:mm')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.post('users/manual-deduct', { deduct: ctx.deductInfo, user: ctx.memberInfo })
        return { data: { success: false } }
      }).then(result => {
        if (result.data.success) {
          ctx.$bvToast.toast(`การคัดเครดิต ${ctx.memberInfo.phoneno} - ${ctx.memberInfo.fname} ${ctx.memberInfo.lname} จำนวน ${ctx.deductInfo.amount}, วันที่ ${moment().format('DD/MM/YYYY HH:mm')}  เรียบร้อย`, {
            variant: 'success',
            title: 'การคัดเครดิตสำเร็จ',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
          })
          ctx.deductInfo = {
            amount: 0.0,
            reason: '',
          }
        }
      })
    },
    async withdrawRequest() {
      if (this.withdrawInfo.reason === undefined || this.withdrawInfo.reason == null || `${this.withdrawInfo.reason}`.length < 5) {
        this.$swal({
          title: 'แจ้งถอนเงิน',
          text: 'กรุณาระบุเหตุผลในการแจ้งถอนเงิน',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      if (parseFloat((`${this.withdrawInfo.amount}`).replace(/,/ig, '')) > 2000000 || parseFloat((`${this.withdrawInfo.amount}`).replace(/,/ig, '')) <= 0) {
        this.$swal({
          title: 'แจ้งถอนเงิน',
          text: 'จำนวนเงินไม่ถูกต้อง',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      const ctx = this
      this.$swal({
        title: 'แจ้งถอนเงิน',
        text: `แจ้งถอนเงินของ ${this.memberInfo.phoneno} - ${this.memberInfo.fname} ${this.memberInfo.lname} จำนวน ${this.withdrawInfo.amount}, วันที่ ${moment().format('DD/MM/YYYY HH:mm')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.post('users/request-withdraw', { withdraw: ctx.withdrawInfo, user: ctx.memberInfo })
        return { data: { success: false } }
      }).then(result => {
        if (result.data.success) {
          ctx.$bvToast.toast(`แจ้งถอนเงินของ ${ctx.memberInfo.phoneno} - ${ctx.memberInfo.fname} ${ctx.memberInfo.lname} จำนวน ${ctx.withdrawInfo.amount}, วันที่ ${moment().format('DD/MM/YYYY HH:mm')}  เรียบร้อย`, {
            variant: 'success',
            title: 'แจ้งถอนเงินสำเร็จ',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
          })
          ctx.withdrawInfo = {
            amount: 0.0,
            reason: '',
          }
        } else {
          ctx.$bvToast.toast(`แจ้งถอนเงินของ ${ctx.memberInfo.phoneno} - ${ctx.memberInfo.fname} ${ctx.memberInfo.lname} จำนวน ${ctx.withdrawInfo.amount}, วันที่ ${moment().format('DD/MM/YYYY HH:mm')} ไม่สำเร็จ - ${result.data.error_message}`, {
            variant: 'danger',
            title: 'แจ้งถอนเงินไม่สำเร็จ',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
